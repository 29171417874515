<template>
  <div class="product">
    <div class="header">
      <h4>المنتجات</h4>
      <div class="actions">
        <button class="add-product" @click="$router.push('/add-product')">
          اضافة منتج
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">الصوره</th>
            <th scope="col">الأسم</th>
            <th scope="col">السعر</th>
            <!-- <th scope="col">التصنيف</th> -->
            <th scope="col">تاريخ الأضافه</th>
            <!-- <th scope="col">اضافة عن طريق</th> -->
            <th scope="col">actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in products" :key="product.id">
            <td>{{ product.id }}</td>
            <td>
              <img class="image" :src="product.image" alt="Product Image" />
            </td>
            <td>{{ product.name }}</td>
            <td>{{ product.price }}</td>
            <!-- <td>{{ product.category }}</td> -->
            <td>{{ formatDateToDMY(product.created_at) }}</td>
            <!-- <td>{{ product.added_by }}</td> -->
            <td>
              <div class="icons">
                <i
                  class="fa-solid fa-trash"
                  @click="Deletproduct(product.id, index)"
                ></i>
                <i class="fa-solid fa-pen" @click="EditProduct(product.id)"></i>
                <i class="fa-solid fa-eye" @click="Showproduct(product.id)"></i>
                <button
                  class="add-product"
                  @click="GetId(product.id)"
                  title="اضافة اسليدر"
                >
                  <i class="fa-brands fa-slideshare"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "HomeView",
  data() {
    return {
      products: [],
    };
  },
  methods: {
    async getProducts() {
      await axios
        .get("/get-products")
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.error("There was an error fetching the products!", error);
        });
    },
    formatDateToDMY(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    async Deletproduct(Id, index) {
      try {
        const result = await Swal.fire({
          title: "تأكيد الحذف ؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "حذف",
        });

        if (result.isConfirmed) {
          await this.Deletproducts(Id, index);
          Swal.fire("Deleted!", "User has been deleted.", "success");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    async Deletproducts(Id, index) {
      try {
        // Sending userId in the request body
        await axios.post(`/delete-product`, { id: Id });
        this.products.splice(index, 1);
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    Showproduct(id) {
      this.$router.push(`/show-product/${id}`);
      localStorage.setItem("id", id);
    },
    EditProduct(id) {
      this.$router.push(`/edit-product/${id}`);
      localStorage.setItem("id", id);
    },
    GetId(id) {
      this.$router.push("/slider");
      localStorage.setItem("id", id);
    },
  },
  async mounted() {
    await this.getProducts();
  },
};
</script>
<style>
.image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>
