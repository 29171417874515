<template>
  <div class="showProduct">
    <h4>عرض المنتج</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">الصوره</th>
            <th scope="col">اسم المنتج</th>
            <th scope="col">السعر</th>
            <th scope="col">العدد</th>
            <th scope="col">تاريخ الأضافه</th>
            <!-- <th scope="col">اضافة بواسطة</th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ products.id }}</td>
            <td>
              <img :src="products.image" alt="" />
            </td>
            <td>{{ products.name }}</td>
            <td>{{ products.price }}</td>
            <td>{{ products.count }}</td>
            <td>{{ formatDateToDMY(products.created_at) }}</td>
            <!-- <td>{{ product.added_by }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <button @click="$router.go(-1)">رجوع</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ShowProduct",
  data() {
    return {
      products: "",
    };
  },
  methods: {
    getProducts() {
      const id = localStorage.getItem("id");
      axios
        .post("/get-product", { id })
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.error("There was an error fetching the products!", error);
        });
    },
    formatDateToDMY(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<style></style>
