<template>
  <div class="avatar-slider">
    <h4>تعديل صور للسليدر</h4>
    <div class="img-preview">
      <form @submit.prevent="updateslider">
        <label for="photo">
          <input type="file" id="photo" @change="previewImage" />
          <p v-if="!imageSrc">
            اختيار صورة
            <i class="fa-solid fa-image"></i>
          </p>
          <div v-if="imageSrc" class="image-container">
            <img :src="imageSrc" alt="Image preview" class="preview-image" />
          </div>
        </label>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
              <label for="name">اسم الصورة</label>
              <input
                class="form-control"
                type="text"
                id="name"
                v-model="name"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
              <label for="alt">alt</label>
              <input class="form-control" type="text" id="alt" v-model="alt" />
            </div>
          </div>
        </div>
        <div class="btn-group">
          <button class="btn" type="submit">تعديل</button>
          <button class="btn" @click="$router.go(-1)" type="button">
            رجوع
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      imageSrc: null, // Holds the source URL of the selected image
      selectedFile: null, // Holds the selected file
      name: "",
      alt: "",
    };
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file; // Store the file
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async updateslider() {
      const formData = new FormData();
      if (this.selectedFile) {
        formData.append("image", this.selectedFile);
      }
      formData.append("name", this.name);
      formData.append("alt", this.alt);

      const product_id = localStorage.getItem("id");
      const image_id = localStorage.getItem("image_id");

      formData.append("product_id", product_id);
      formData.append("image_id", image_id);

      try {
        const response = await axios.post("/update-product-image", formData);
        if (response.data.status === true) {
          // Check for successful status code
          Swal.fire({
            position: "center",
            icon: "success",
            title: "تم التعديل بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ",
          text: "لم يتم التعديل، حاول مرة أخرى!",
          showConfirmButton: true,
        });
      }
    },
    async fetchslider() {
      const product_id = localStorage.getItem("id");
      const image_id = localStorage.getItem("image_id");
      try {
        const res = await axios.post("/edit-product-image", {
          product_id,
          image_id,
        });
        const EditData = res.data.data;
        this.imageSrc = EditData.image || "";
        this.name = EditData.name || "";
        this.alt = EditData.alt || "";
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.fetchslider();
  },
};
</script>

<style scoped>
.avatar-slider {
  text-align: center;
}

.img-preview {
  margin-top: 20px;
}

label {
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

p {
  margin: 0;
  font-size: 16px;
}

.image-container {
  margin-top: 10px;
}

.preview-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: cover;
}

.btn-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

button {
  width: 120px;
  font-family: "regular";
  background-color: #06456b;
  color: white;
  border-radius: 5px;
  border: 0;
  padding: 0.5rem;
  transition: 0.3s;
}

button:hover {
  border: 1px solid #06456b;
  color: #06456b;
  background-color: white;
}

.form-group label {
  font-family: "regular";
  margin-bottom: 5px;
  height: unset;
  border: 0;
}

.form-group input {
  width: 100%;
}
</style>
