<template>
  <div class="sidebar">
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">E-Commerce</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul>
          <li>
            <router-link to="/products" @click="closeOffcanvas">
              <i class="fa-solid fa-truck-fast"></i> المنتجات
            </router-link>
          </li>
          <li>
            <router-link to="/categories" @click="closeOffcanvas">
              <i class="fa-solid fa-layer-group"></i> الفئات
            </router-link>
          </li>
          <li>
            <router-link to="/client" @click="closeOffcanvas">
              <i class="fa-solid fa-users-line"></i> العملاء
            </router-link>
          </li>
          <li>
            <router-link to="/users" @click="fetchUsersData">
              <i class="fa-solid fa-people-group"></i> الموظفين
            </router-link>
          </li>
          <li>
            <router-link to="/campaigns" @click="closeOffcanvas">
              <i class="fa-regular fa-compass"></i> الحملات
            </router-link>
          </li>
          <li @click="log_out()">
            <i class="fa-solid fa-arrow-right-from-bracket"></i>
            تسجيل الخروج
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as bootstrap from "bootstrap";

import { mapActions } from "vuex";

import axios from "axios";
export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions["logOut"],
    closeOffcanvas() {
      const offcanvasElement = document.getElementById("offcanvasRight");
      if (offcanvasElement) {
        const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
        if (bsOffcanvas) {
          bsOffcanvas.hide();
        } else {
          new bootstrap.Offcanvas(offcanvasElement).hide();
        }
      }
    },
    async fetchUsersData() {
      this.closeOffcanvas(); // Close offcanvas when clicking on /users
      try {
        const response = await axios.get("/get-admins");
        this.$emit("update:users", response.data.data); // Emit the fetched data to update the prop
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    log_out() {
      this.closeOffcanvas();
      this.$store.dispatch("logOut");

      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.offcanvas-backdrop.show {
  display: none !important;
}
</style>
