<template>
  <div class="create-acount">
    <div class="form-create-acount">
      <h4>تسجيل حساب جديد</h4>
      <form action="" @submit.prevent>
        <div class="form-group">
          <label for="name">ادخل الأسم</label>
          <div class="name">
            <i class="fa-solid fa-user"></i>
            <input
              id="email"
              type="text"
              placeholder="Enter your name"
              class="form-control"
            />
          </div>
          <label for="phone">ادخل رقم الهاتف</label>
          <div class="phone">
            <i class="fa-solid fa-phone"></i>
            <input
              id="phone"
              type="tel"
              placeholder="Enter your phone"
              class="form-control"
            />
          </div>
          <label for="email">ادخل البريد الألكتروني</label>
          <div class="email">
            <i class="fa-solid fa-envelope"></i>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              class="form-control"
            />
          </div>
          <label for="password">ادخل كلمة المرور</label>
          <div class="password">
            <i
              class="fa-solid fa-lock"
              @click="togglePasswordVisibility('password')"
            ></i>
            <input
              id="password"
              :type="passwordType"
              placeholder="Enter your password"
              class="form-control"
            />
          </div>
          <label for="password">تاكيد كلمة المرور</label>
          <div class="password">
            <i
              class="fa-solid fa-lock"
              @click="togglePasswordVisibility('confirmPassword')"
            ></i>
            <input
              id="password"
              :type="confirmPasswordType"
              placeholder="Enter your password"
              class="form-control"
            />
          </div>
        </div>
        <router-link to="/">تسجيل الدخول</router-link>
        <button type="submit" class="btn">انشاء حساب</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateAcountView",
  data() {
    return {
      passwordType: "password",
      confirmPasswordType: "password",
    };
  },
  methods: {
    togglePasswordVisibility(field) {
      if (field === "password") {
        this.passwordType =
          this.passwordType === "password" ? "text" : "password";
      } else if (field === "confirmPassword") {
        this.confirmPasswordType =
          this.confirmPasswordType === "password" ? "text" : "password";
      }
    },
  },
};
</script>
