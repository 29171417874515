<template>
  <div class="edit-campaign">
    <div class="form-add-campaign">
      <h4>تعديل الحملة</h4>
      <form @submit.prevent="updateCampaign">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">اسم الحملة</label>
              <input
                id="name"
                type="text"
                placeholder="Enter campaign name"
                class="form-control"
                v-model="campaignName"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="description">الوصف</label>
              <textarea
                name="description"
                id="description"
                placeholder="Enter campaign description"
                class="form-control"
                v-model="campaignDescription"
              ></textarea>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="slug">Slug</label>
              <input
                id="slug"
                type="text"
                placeholder="Enter slug"
                class="form-control"
                v-model="slug"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="url">URL</label>
              <input
                id="url"
                type="text"
                class="form-control"
                :value="generatedUrl"
                readonly
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="product">المنتجات</label>
              <multiselect
                v-model="selectedProduct"
                :options="Products"
                :multiple="false"
                :taggable="true"
                placeholder="Select a Product"
                label="name"
                track-by="id"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="category">الفئة</label>
              <multiselect
                v-model="selectedCategory"
                :options="categories"
                :multiple="false"
                :taggable="true"
                placeholder="Select a Category"
                label="name"
                track-by="id"
              />
            </div>
          </div>
        </div>

        <button type="submit" class="btn">تعديل</button>
        <button @click.prevent="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import "vue-multiselect/dist/vue-multiselect.css";

axios.defaults.baseURL = "https://back.commerce.x-coders.net/api/dashboard";

export default {
  name: "EditCampaign",
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedProduct: null,
      Products: [],
      categories: [],
      selectedCategory: null,
      campaignName: "",
      campaignDescription: "",
      slug: "",
    };
  },
  computed: {
    generatedUrl() {
      const slugValue = (this.slug || "")
        .trim()
        .toLowerCase()
        .replace(/\s+/g, "-");
      return `https://commerce.x-coders.net/${slugValue}`;
    },
  },
  mounted() {
    this.loadCampaign();
  },
  methods: {
    async loadCampaign() {
      try {
        const response = await axios.post("/edit-campaign", {
          id: this.$route.params.id,
        });
        const data = response.data.data;
        this.campaignName = data.campaign.name;
        this.campaignDescription = data.campaign.description;
        this.slug = data.campaign.url || "";
        this.selectedProduct = data.campaign.product;
        this.selectedCategory = data.campaign.category;
      } catch (error) {
        console.error("Error loading campaign:", error);
      }
    },
    async updateCampaign() {
      try {
        const response = await axios.post("/update-campaign", {
          id: this.$route.params.id,
          name: this.campaignName,
          description: this.campaignDescription,
          url: this.generatedUrl,
          product_id: this.selectedProduct ? this.selectedProduct.id : null,
          category_id: this.selectedCategory ? this.selectedCategory.id : null,
        });
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: "تم تحديث الحملة بنجاح!",
          }).then(() => {
            this.$router.go(-1);
          });
        }
      } catch (error) {
        console.error("Error updating campaign:", error);
      }
    },
  },
};
</script>

<style>
.multiselect__tag {
  background-color: #06456b !important;
}
.multiselect__option--highlight {
  background-color: #06456b !important;
}
</style>
