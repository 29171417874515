<template>
  <div class="add-product">
    <div class="form-add-product">
      <h4>اضافة منتج</h4>
      <form @submit.prevent="addProduct">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productName"> اسم المنتج</label>
              <div class="name">
                <input
                  id="productName"
                  type="text"
                  placeholder="Enter product name"
                  class="form-control"
                  v-model="productName"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productCategory"> نوع المنتج</label>
              <multiselect
                v-model="selectedCategories"
                :options="categories"
                :multiple="true"
                :taggable="true"
                placeholder="Select categories"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productPrice"> السعر للقطعه</label>
              <div class="name">
                <input
                  id="productPrice"
                  type="number"
                  placeholder="Enter product price"
                  class="form-control"
                  v-model="productPrice"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productDescription"> الوصف</label>
              <div class="name">
                <input
                  id="productDescription"
                  type="text"
                  placeholder="Enter product description"
                  class="form-control"
                  v-model="productDescription"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productCount"> العدد</label>
              <div class="name">
                <input
                  id="productCount"
                  type="number"
                  placeholder="Enter product count"
                  class="form-control"
                  v-model="productCount"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div class="photo">
                <label for="photo" class="photo-label">
                  <p>صورة المنتج</p>
                  <img
                    :src="imagePreview"
                    class="avatar"
                    @click.stop.prevent="triggerFileInput"
                    aria-label="اختيار صورة "
                  />
                </label>
                <input
                  type="file"
                  class="form-control"
                  id="photo"
                  ref="fileInput"
                  @change="handleFileChange"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn">اضافة</button>
        <button @click="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      imagePreview: null,
      productName: "",
      productPrice: "",
      productDescription: "",
      productCount: "",
      selectedCategories: [],
      categories: [],
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Validate file type and size
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "الملف غير مدعوم. الرجاء اختيار صورة.",
        });
        this.imagePreview = null;
        return;
      }

      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حجم الصورة أكبر من الحد المسموح به (5MB).",
        });
        this.imagePreview = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.errorMessage = null; // Clear previous errors
      };
      reader.onerror = () => {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حدث خطأ أثناء قراءة الصورة.",
        });
        this.imagePreview = null;
      };
      reader.readAsDataURL(file);
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async addProduct() {
      const formData = new FormData();
      const fileInput = this.$refs.fileInput.files[0];
      if (fileInput) formData.append("image", fileInput);
      formData.append("name", this.productName);
      formData.append("price", this.productPrice);
      formData.append("description", this.productDescription);
      formData.append("count", this.productCount);
      formData.append("categories", this.selectedCategories);
      console.log(this.imagePreview);

      try {
        const response = await axios.post("/store-product", formData);
        console.log(response.data);

        // Show SweetAlert2 success message
        Swal.fire({
          icon: "success",
          title: "Product Added!",
          text: "The product was added successfully.",
          timer: 1500, // Optional: auto-close the alert after 1.5 seconds
          showConfirmButton: false, // Optional: hide the confirm button
        }).then(() => {
          this.$router.push("/products");
        });
      } catch (error) {
        console.error("There was an error adding the product!", error);

        // Show SweetAlert2 error message
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There was an error adding the product!",
          footer: '<a href="#">Why do I have this issue?</a>', // Optional: add a footer link
        });
      }
    },

    async fetchCategories() {
      await axios
        .get("/get-categories")
        .then((response) => {
          this.categories = response.data.data;
        })
        .catch((error) => {
          console.error("There was an error fetching the categories!", error);
        });
    },
  },
  created() {
    this.fetchCategories();
  },
};
</script>

<style>
.image-preview {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}
.multiselect__tag {
  background-color: #06456b !important;
}
.multiselect__option--highlight {
  background-color: #06456b !important;
}

input#photo {
  display: none;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  cursor: pointer;
}
</style>
