<template>
  <div class="client">
    <div class="header">
      <h4>العملاء</h4>
      <!-- <button class="add-product" @click="$router.push('/add-product')">
        اضافة منتج
      </button> -->
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">الأسم</th>
            <th scope="col">رقم الهاتف</th>
            <th scope="col">بريد الألكتروني</th>
            <th scope="col">تاريخ الأضافه</th>
            <th scope="col">الإجراءات</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(client, index) in clients" :key="client.id">
            <td>{{ client.id }}</td>
            <td>{{ client.name }}</td>
            <td>{{ client.phone }}</td>
            <td>{{ client.email }}</td>
            <td>{{ formatDateToDMY(client.created_at) }}</td>
            <td>
              <div class="icons">
                <i
                  class="fa-solid fa-trash"
                  @click="confirmDelete(client.id, index)"
                ></i>

                <i
                  class="fa-solid fa-eye"
                  @click="confirmEdit(client.id, index)"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "ClientView",
  data() {
    return {
      clients: [],
    };
  },
  mounted() {
    this.getClients();
  },
  methods: {
    async getClients() {
      try {
        const response = await axios.get("/get-users");
        this.clients = response.data.data;
        console.log(this.clients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    },

    formatDateToDMY(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },

    async confirmDelete(clientId, index) {
      try {
        const result = await Swal.fire({
          title: "تأكيد الحذف ؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "حذف",
        });

        if (result.isConfirmed) {
          await this.deleteClient(clientId, index);
          Swal.fire("تم الحذف!", "تم حذف العميل بنجاح.", "success");
        }
      } catch (error) {
        console.error("Error confirming delete:", error);
      }
    },

    async deleteClient(clientId, index) {
      try {
        await axios.post("/delete-user", { id: clientId });
        this.clients.splice(index, 1);
        // Optionally, refresh data or show a message
      } catch (error) {
        console.error("Error deleting client:", error);
      }
    },

    confirmEdit(clientId) {
      this.$router.push(`/show-client/${clientId}`);
      localStorage.setItem("id", clientId);
    },
  },
};
</script>

<style scoped>
/* Add relevant styles for your component here */
</style>
