<template>
  <div class="navbar">
    <nav class="navbar navbar-expand-lg bg-light">
      <div class="container-fluid">
        <button
          class="btn btn-primary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i class="fa-solid fa-bars-staggered"></i>
        </button>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div class="information">
                  <h4>{{ user.name }}</h4>
                </div>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="#">{{ user.email }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">{{ user.phone }}</a>
                </li>
                <li><hr class="dropdown-divider" /></li>
              </ul>
            </li>
          </ul>

          <a class="navbar-brand" style="font-family: bold; color: #06456b"
            >E-Commerce</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NavBar",
  data() {
    return {
      user: {}, // Initialize as an object
    };
  },
  async mounted() {
    // Fetch user profile data when the component is mounted
    await this.fetchUserProfile();
  },
  methods: {
    async fetchUserProfile() {
      try {
        const response = await axios.get("/profile");
        this.user = response.data.data;
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
