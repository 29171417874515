<template>
  <div class="add-user">
    <div class="header">
      <h4>اضافة موظف</h4>
    </div>
    <div class="form-add-user">
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="photo">
                <label for="photo" class="photo-label">
                  <h4>الصورة</h4>
                  <img
                    :src="imagePreview"
                    class="avatar"
                    @click.stop.prevent="triggerFileInput"
                    aria-label="اختيار صورة المستخدم"
                  />
                </label>
                <input
                  type="file"
                  class="form-control"
                  id="photo"
                  ref="fileInput"
                  @change="handleFileChange"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="name">
                <label for="name">الأسم</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="ادخل الأسم"
                  v-model="name"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="phone">
                <label for="phone">رقم الهاتف</label>
                <input
                  type="number"
                  class="form-control"
                  id="phone"
                  placeholder="ادخل رقم الهاتف"
                  v-model="phone"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="email">
                <label for="email">البريد الالكتروني</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="ادخل البريد الالكتروني"
                  v-model="email"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="password">
                <label for="password">كلمة المرور</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  placeholder="ادخل كلمة المرور"
                  v-model="password"
                />
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">اضافة</button>
        <button type="button" @click="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      imagePreview: null,
      name: "",
      phone: "",
      password: "",
      email: "",
      errorMessage: null,
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Validate file type and size
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "الملف غير مدعوم. الرجاء اختيار صورة.",
        });
        this.imagePreview = null;
        return;
      }

      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حجم الصورة أكبر من الحد المسموح به (5MB).",
        });
        this.imagePreview = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.errorMessage = null; // Clear previous errors
      };
      reader.onerror = () => {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حدث خطأ أثناء قراءة الصورة.",
        });
        this.imagePreview = null;
      };
      reader.readAsDataURL(file);
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    async handleSubmit() {
      try {
        const formData = new FormData();
        const fileInput = this.$refs.fileInput.files[0];
        if (fileInput) formData.append("image", fileInput);
        formData.append("name", this.name);
        formData.append("phone", this.phone);
        formData.append("email", this.email);
        formData.append("password", this.password);

        const response = await axios.post("/store-admin-account", formData);

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تمت الإضافة",
            text: "تم إضافة الموظف بنجاح.",
          });
          this.$router.push("/users");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message || "حدث خطأ أثناء إضافة الموظف.",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حدث خطأ في الشبكة. يرجى المحاولة لاحقاً.",
        });
      }
    },
  },
};
</script>

<style scoped></style>
