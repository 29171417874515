<template>
  <div class="add-category">
    <div class="form-add-category">
      <h4>اضافة فئه</h4>
      <form action="" @submit.prevent="AddCategory">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productName"> اسم الفئه</label>
              <div class="name">
                <input
                  id="productcategory"
                  type="text"
                  placeholder="Enter category name"
                  class="form-control"
                  v-model="name"
                />
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn">اضافة</button>
        <button @click="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AddCategories",
  data() {
    return {
      name: "",
    };
  },
  methods: {
    async AddCategory() {
      try {
        const response = await axios.post("/store-category", {
          name: this.name,
        });

        // Check if the response status code is 200 (OK)
        if (response.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "تم اضافة الفئه بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("/categories");
        } else {
          // Handle other status codes or failure responses
          Swal.fire({
            position: "center",
            icon: "error",
            title: "حدث خطأ، يرجى المحاولة مرة أخرى",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        // Handle errors from the API call
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ، يرجى المحاولة مرة أخرى",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style></style>
