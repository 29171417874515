<template>
  <NavBar v-if="showNavbar" />

  <div class="content">
    <slot></slot>
  </div>
  <SideBar />
</template>
<script>
import SideBar from "./SideBar.vue";
import NavBar from "./NavBar.vue";

export default {
  components: {
    SideBar,
    NavBar,
  },
  computed: {
    showNavbar() {
      return this.$route.meta.showNavbar !== false;
    },
  },
};
</script>
