<template>
  <div class="users">
    <div class="header">
      <h4>الموظفين</h4>
      <button class="add-product" @click="$router.push('/add-user')">
        اضافة موظف
      </button>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">الصوره</th>
            <th scope="col">الأسم</th>
            <th scope="col">رقم الهاتف</th>
            <th scope="col">البريد الألكتروني</th>
            <th scope="col">تاريخ الأضافه</th>
            <th scope="col">actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>
              <img :src="user.image" alt="User Image" class="user-image" />
            </td>
            <td>{{ user.name }}</td>
            <td>{{ user.phone }}</td>
            <td>{{ user.email }}</td>
            <td>{{ formatDateToDMY(user.created_at) }}</td>
            <td>
              <div class="icons">
                <i
                  class="fa-solid fa-trash"
                  v-if="user.is_super_admin === 0"
                  @click="confirmDelete(user.id, index)"
                ></i>
                <i
                  class="fa-solid fa-pen"
                  @click="redirectFunction(user.id)"
                ></i>
                <i
                  class="fa-solid fa-eye"
                  @click="redirectShowFunction(user.id)"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "UsersView",
  data() {
    return {
      users: [],
      id: "",
    };
  },
  mounted() {
    this.fetchUsersData();
  },
  methods: {
    async fetchUsersData() {
      try {
        const response = await axios.get("/get-admins");
        this.users = response.data.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async confirmDelete(userId, index) {
      try {
        const result = await Swal.fire({
          title: "تأكيد الحذف ؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "حذف",
        });

        if (result.isConfirmed) {
          await this.deleteUser(userId, index);
          Swal.fire("Deleted!", "User has been deleted.", "success");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    async deleteUser(userId, index) {
      try {
        // Sending userId in the request body
        await axios.post(`/delete-admin-account`, { id: userId });
        this.users.splice(index, 1);
        // Refresh user data after deletion
        // this.fetchUsersData();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    formatDateToDMY(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },

    redirectFunction(id) {
      this.$router.push(`/edit-user/${id}`);
      localStorage.setItem("id", id);
    },
    redirectShowFunction(id) {
      this.$router.push(`/show-user/${id}`);
      localStorage.setItem("id", id);
    },
  },
};
</script>

<style scoped>
.user-image {
  width: 50px; /* Adjust size as needed */
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
