<template>
  <div class="edit-category">
    <div class="form-edit-category">
      <h4>تعديل الفئه</h4>
      <form action="" @submit.prevent="UpdateCategory">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productcategory"> اسم الفئه</label>
              <div class="name">
                <input
                  id="productcategory"
                  type="text"
                  placeholder="Enter category name"
                  class="form-control"
                  v-model="name"
                />
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn">تعديل</button>
        <button @click="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  name: "EditCategories",
  data() {
    return {
      name: "",
    };
  },
  created() {
    this.fetchCategory();
  },
  methods: {
    async fetchCategory() {
      const id = localStorage.getItem("id");
      const res = await axios.post("/edit-category", { id });
      const EditData = res.data.data;
      this.name = EditData.name || "";
    },
    async UpdateCategory() {
      const formData = new FormData();
      formData.append("id", localStorage.getItem("id"));
      formData.append("name", this.name);

      const res = await axios.post("/update-category", formData);
      if (res.data.status === true) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم التعديل بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push({ name: "categories" });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "حدث خطأ",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style></style>
