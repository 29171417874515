<template>
  <div class="showCampaign">
    <div class="header">
      <h4>عرض الحملة</h4>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">الأسم</th>
            <th scope="col">البريد الألكتروني</th>
            <th scope="col">رقم الهاتف</th>
            <th scope="col">اسم المنتج</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>@mdo</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="add-product" @click="$router.go(-1)">الرجوع</button>
  </div>
</template>
