<template>
  <div class="add-campaign">
    <div class="form-add-campaign">
      <h4>اضافة حملة</h4>
      <form @submit.prevent="addCampaign">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="name">اسم الحملة</label>
              <div class="name">
                <input
                  id="name"
                  type="text"
                  placeholder="Enter campaign name"
                  class="form-control"
                  v-model="campaignName"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="description">الوصف الحملة</label>
              <div class="description">
                <textarea
                  name="description"
                  id="description"
                  placeholder="Enter campaign description"
                  class="form-control"
                  v-model="campaignDescription"
                ></textarea>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="slug">Slug</label>
              <div class="slug">
                <input
                  id="slug"
                  type="text"
                  placeholder="Enter slug"
                  class="form-control"
                  v-model="slug"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="url">URL</label>
              <div class="url">
                <input
                  id="url"
                  type="text"
                  class="form-control"
                  :value="generatedUrl"
                  readonly
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="product">المنتجات</label>
              <multiselect
                v-model="selectedProduct"
                :options="Products"
                :multiple="false"
                :taggable="true"
                placeholder="Select a Product"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="category">الفئة</label>
              <multiselect
                v-model="selectedCategory"
                :options="categories"
                :multiple="false"
                :taggable="true"
                placeholder="Select a Category"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
          </div>
        </div>

        <button type="submit" class="btn">اضافة</button>
        <button @click="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

// Set the default base URL for axios
axios.defaults.baseURL = "https://back.commerce.x-coders.net/api/dashboard";

export default {
  name: "AddCampaigns",
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedProduct: null,
      Products: [],
      categories: [],
      selectedCategory: null,
      campaignName: "",
      campaignDescription: "",
      slug: "",
    };
  },
  computed: {
    generatedUrl() {
      const slugValue = this.slug.trim().toLowerCase().replace(/\s+/g, "-");
      return `
      https://portal.commerce.x-coders.net/${slugValue}`;
    },
  },
  created() {
    this.FetchData();
  },
  methods: {
    addCampaign() {
      axios
        .post("/store-campaign", {
          name: this.campaignName,
          description: this.campaignDescription,
          url: this.generatedUrl,
          slug: this.slug,
          product_id: this.selectedProduct ? this.selectedProduct.id : null,
          category_id: this.selectedCategory ? this.selectedCategory.id : null,
        })
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "تمت العملية بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("/campaigns");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async FetchData() {
      await axios
        .get("/create-campaign")
        .then((response) => {
          console.log(response.data);
          this.Products = response.data.data.products;
          this.categories = response.data.data.categories;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.multiselect__tag {
  background-color: #06456b !important;
}
.multiselect__option--highlight {
  background-color: #06456b !important;
}
</style>
