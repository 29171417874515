<template>
  <div class="Categories">
    <div class="header">
      <h4>الفيئات</h4>
      <div class="actions">
        <button class="add-product" @click="$router.push('/add-category')">
          اضافة فئه
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">الأسم</th>
            <th scope="col">actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(category, index) in categories" :key="category.id">
            <td>{{ category.name }}</td>
            <td>
              <div class="icons">
                <i
                  class="fa-solid fa-trash"
                  @click="DeleteCatgory(category.id, index)"
                ></i>
                <i
                  class="fa-solid fa-pen"
                  @click="EditCategory(category.id)"
                ></i>
                <i
                  class="fa-solid fa-eye"
                  @click="ViewCategory(category.id)"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "categories_view",
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    async getCategories() {
      await axios
        .get("/get-categories")
        .then((response) => {
          this.categories = response.data.data;
          console.log(this.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    EditCategory(id) {
      this.$router.push(`/edit-category/${id}`);
      localStorage.setItem("id", id);
    },
    ViewCategory(id) {
      this.$router.push(`/show-category/${id}`);
      localStorage.setItem("id", id);
    },
    async DeleteCatgory(Id, index) {
      try {
        const result = await Swal.fire({
          title: "تأكيد الحذف ؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "حذف",
        });

        if (result.isConfirmed) {
          await this.deleteCategory(Id, index);
          Swal.fire("Deleted!", "User has been deleted.", "success");
        }
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    async deleteCategory(Id, index) {
      try {
        // Sending userId in the request body
        await axios.post(`/delete-category`, { id: Id });
        this.categories.splice(index, 1);
        // Refresh user data after deletion
        // this.getCategories();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>
