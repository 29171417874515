<template>
  <div class="erorr-page">
    <h4>الصفحة المطلوبة غير موجودة</h4>
    <button @click="$router.push('/products')">رجوع</button>
  </div>
</template>

<script>
export default {
  name: "ErorrPage",
};
</script>
