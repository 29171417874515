<template>
  <div class="showuser">
    <h4>عرض موظف</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">الصوره</th>
            <th scope="col">اسم المستخدم</th>
            <th scope="col">البريد الألكتروني</th>
            <th scope="col">رقم الهاتف</th>
            <th scope="col">تاريخ الأضافه</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ users.id }}</td>
            <td>
              <img :src="users.image" alt="" />
            </td>
            <td>{{ users.name }}</td>
            <td>{{ users.email }}</td>
            <td>{{ users.phone }}</td>
            <td>{{ formatDateToDMY(users.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button @click="$router.go(-1)">رجوع</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ShowUser",
  data() {
    return {
      users: "",
    };
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      const id = localStorage.getItem("id");
      axios
        .post("/get-admin-account", { id })
        .then((res) => {
          this.users = res.data.data;
        })
        .catch((error) => {
          console.error("There was ann error fetching the user data!", error);
        });
    },
    formatDateToDMY(dateString) {
      const date = new Date(dateString);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
  },
  watch: {
    "$route.params.id": "fetchUserData",
  },
};
</script>

<style>
/* Your styles here */
</style>
