<template>
  <div class="restPassword">
    <div class="form-restPassword">
      <h4>نسيت كلمه المرور</h4>
      <p>
        ادخل البريد الالكترونى الذي قمت بالتسجيل به لاستلام كود يمكنك من خلاله
        استرجاع كلمة المرور
      </p>
      <form action="" @submit.prevent>
        <div class="form-group">
          <label for="email">ادخل البريد الالكترونى</label>
          <div class="email">
            <i class="fa-solid fa-envelope"></i>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              class="form-control"
            />
          </div>
          <button type="submit" class="btn">ارسال</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {};
</script>
