<template>
  <div class="show-categories">
    <div class="header">
      <h4>عرض الفيئات</h4>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">الأسم</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in categories" :key="category.id">
            <td>{{ category.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="back" @click="$router.go(-1)">رجوع</button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "categories_view",
  data() {
    return {
      categories: "",
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      const id = localStorage.getItem("id");
      await axios
        .post("/get-category", { id })
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          console.error("There was an error fetching the categories!", error);
        });
    },
  },
};
</script>
