<template>
  <div class="edit-user">
    <div class="header">
      <h4>تعديل موظف</h4>
    </div>
    <div class="form-add-user">
      <form @submit.prevent="UpdateUsersData">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="photo">
                <label for="photo" class="photo-label">
                  <h4>الصورة</h4>
                  <img
                    :src="imagePreview"
                    class="avatar"
                    @click.stop.prevent="triggerFileInput"
                    aria-label="اختيار صورة المستخدم"
                  />
                </label>
                <input
                  type="file"
                  class="form-control"
                  id="photo"
                  ref="fileInput"
                  @change="handleFileChange"
                  aria-hidden="true"
                />
                <p v-if="errorMessage" class="error-message">
                  {{ errorMessage }}
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="name">
                <label for="name">الأسم</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="ادخل الأسم"
                  v-model="name"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="email">
                <label for="email">الأسم</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="ادخل الأسم"
                  v-model="email"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="phone">
                <label for="phone">رقم الهاتف</label>
                <input
                  type="number"
                  class="form-control"
                  id="phone"
                  placeholder="ادخل رقم الهاتف"
                  v-model="phone"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="password">
                <label for="password">كلمة المرور</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  placeholder="ادخل كلمة المرور"
                  v-model="password"
                />
              </div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">تعديل</button>
        <button type="button" class="btn btn-secondary" @click="$router.go(-1)">
          رجوع
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2"; // Ensure you have sweetalert2 installed

export default {
  data() {
    return {
      imagePreview: null,
      name: "",
      phone: "",
      password: "",
      email: "",
      errorMessage: null,
    };
  },
  created() {
    this.fetchUsersData(); // Fetch user data when the component is created
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Validate file type (e.g., only images)
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        this.errorMessage = "الملف غير مدعوم. الرجاء اختيار صورة.";
        this.imagePreview = null;
        return;
      }

      // Validate file size (e.g., no larger than 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        this.errorMessage = "حجم الصورة أكبر من الحد المسموح به (5MB).";
        this.imagePreview = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.errorMessage = null; // Clear any previous errors
      };
      reader.onerror = () => {
        this.errorMessage = "حدث خطأ أثناء قراءة الصورة.";
        this.imagePreview = null;
      };
      reader.readAsDataURL(file);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async fetchUsersData() {
      try {
        const id = localStorage.getItem("id");
        const response = await axios.post("/edit-admin-account", { id });
        const userData = response.data.data;

        this.name = userData.name || "";
        this.phone = userData.phone || "";
        this.password = userData.password || "";
        this.email = userData.email || "";
        this.imagePreview = userData.image || null;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async UpdateUsersData() {
      try {
        const formData = new FormData();
        formData.append("id", localStorage.getItem("id"));
        formData.append("name", this.name);
        formData.append("phone", this.phone);
        formData.append("email", this.email);
        formData.append("password", this.password);
        if (this.imagePreview) {
          const response = await fetch(this.imagePreview);
          const blob = await response.blob();
          formData.append("image", blob, "profile-image.png");
        }

        const res = await axios.post("/update-admin-account", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "تمت التعديل بنجاح",
            text: "تم تعديل بيانات الموظف بنجاح.",
          });
          this.$router.push("/users");
        }
      } catch (error) {
        console.error("Error updating user data:", error);
        Swal.fire({
          icon: "error",
          title: "حدث خطأ",
          text: "فشل في تحديث بيانات الموظف.",
        });
      }
    },
  },
};
</script>
