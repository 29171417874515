<template>
  <div class="edit-product">
    <div class="form-edit-product">
      <h4>تعديل المنتج</h4>
      <form @submit.prevent="UpdateProduct">
        <div class="form-group">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productName">اسم المنتج</label>
              <input
                id="productName"
                type="text"
                placeholder="Enter product name"
                class="form-control"
                v-model="productName"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productCategory">نوع المنتج</label>
              <multiselect
                v-model="selectedCategories"
                :options="categories"
                :multiple="true"
                :taggable="true"
                placeholder="Select categories"
                label="name"
                track-by="id"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productPrice">السعر للقطعه</label>
              <input
                id="productPrice"
                type="number"
                placeholder="Enter product price"
                class="form-control"
                v-model="productPrice"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productDescription">الوصف</label>
              <input
                id="productDescription"
                type="text"
                placeholder="Enter product description"
                class="form-control"
                v-model="productDescription"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label for="productCount">العدد</label>
              <input
                id="productCount"
                type="number"
                placeholder="Enter product count"
                class="form-control"
                v-model="productCount"
              />
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div class="photo">
                <label for="photo" class="photo-label">
                  <p>صورة المنتج</p>
                  <img
                    :src="imagePreview"
                    class="avatar"
                    @click.stop.prevent="triggerFileInput"
                    aria-label="اختيار صورة "
                  />
                </label>
                <input
                  type="file"
                  id="photo"
                  ref="fileInput"
                  @change="handleFileChange"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn">تعديل</button>
        <button type="button" class="btn" @click="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EditProduct",
  components: {
    Multiselect,
  },
  data() {
    return {
      productName: "",
      selectedCategories: [],
      productPrice: "",
      productDescription: "",
      productCount: "",
      imagePreview: null,
      categories: [], // Assuming you have a list of categories to fetch
    };
  },
  created() {
    this.fetchProduct();
  },

  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Validate file type and size
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "الملف غير مدعوم. الرجاء اختيار صورة.",
        });
        this.imagePreview = null;
        return;
      }

      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حجم الصورة أكبر من الحد المسموح به (5MB).",
        });
        this.imagePreview = null;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      reader.onerror = () => {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "حدث خطأ أثناء قراءة الصورة.",
        });
        this.imagePreview = null;
      };
      reader.readAsDataURL(file);
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async fetchProduct() {
      try {
        const id = localStorage.getItem("id");
        const res = await axios.post("/edit-product", { id });
        const EditData = res.data.data;
        this.productName = EditData.name || "";
        this.selectedCategories = EditData.categories || [];
        this.productPrice = EditData.price || "";
        this.productDescription = EditData.description || "";
        this.productCount = EditData.count || "";
        this.imagePreview = EditData.image || null; // Assuming image URL or base64 string
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    },
    async UpdateProduct() {
      try {
        const formData = new FormData();
        formData.append("id", localStorage.getItem("id"));
        formData.append("name", this.productName);
        formData.append("categories", JSON.stringify(this.selectedCategories));
        formData.append("price", this.productPrice);
        formData.append("description", this.productDescription);
        formData.append("count", this.productCount);

        if (this.imagePreview) {
          const response = await fetch(this.imagePreview);
          const blob = await response.blob();
          formData.append("image", blob, "product-image.png");
        }

        const res = await axios.post("/update-product", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "تمت التعديل بنجاح",
            text: "تم تعديل بيانات المنتج بنجاح.",
          });
          this.$router.push("/products"); // Redirect to product list
        }
      } catch (error) {
        console.error("Error updating product data:", error);
        Swal.fire({
          icon: "error",
          title: "حدث خطأ",
          text: "فشل في تحديث بيانات المنتج.",
        });
      }
    },
  },
};
</script>

<style scoped>
/* Add your component styles here */
</style>
