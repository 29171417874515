import { createRouter, createWebHistory } from "vue-router";
import ProductsView from "../views/ProductsView.vue";
import ClientView from "@/views/ClientView.vue";
import UsersView from "@/views/UsersView.vue";
import CampaignsView from "@/views/CampaignsView.vue";
import LoginView from "@/views/regester/LoginView.vue";
import CreateAcount from "@/views/regester/CreateAcount.vue";
import RestPassword from "@/views/regester/RestPassword.vue";
import AddProduct from "@/views/AddProduct.vue";
import EditProduct from "@/views/EditProduct.vue";
import ShowProduct from "@/views/ShowProduct.vue";
import ShowClinet from "@/views/ShowClinet.vue";
import AddUser from "@/views/AddUser.vue";
import EditUser from "@/views/EditUser.vue";
import ShowUser from "@/views/ShowUser.vue";
import AddCampaigns from "@/views/AddCampaigns.vue";
import EditCampaign from "@/views/EditCampaign.vue";
import ShowCampaign from "@/views/ShowCampaign.vue";
import AddSlider from "@/views/AddSlider.vue";
import Slider from "@/views/SliderPage.vue";
import EditSlider from "@/views/EditSlider.vue";
import CategoriesView from "@/views/categories_view.vue";
import AddCategories from "@/views/AddCategories.vue";
import EditCategory from "@/views/EditCategory.vue";
import ErrorPage from "@/views/ErorrPage.vue";
import ShowCategory from "@/views/ShowCategory.vue";
const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
    meta: { showNavbar: false },
  },
  {
    path: "/create-acount",
    name: "create-acount",
    component: CreateAcount,
    meta: { showNavbar: false },
  },
  {
    path: "/rest-Password",
    name: "rest-Password",
    component: RestPassword,
    meta: { showNavbar: false },
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
    meta: { showNavbar: true },
  },
  {
    path: "/client",
    name: "client",
    component: ClientView,
    meta: { showNavbar: true },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: { showNavbar: true },
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: CampaignsView,
    meta: { showNavbar: true },
  },
  {
    path: "/add-product",
    name: "add-product",
    component: AddProduct,
    meta: { showNavbar: true },
  },
  {
    path: "/edit-product/:id",
    name: "edit-product",
    component: EditProduct,
    meta: { showNavbar: true },
  },
  {
    path: "/show-product/:id",
    name: "show-product",
    component: ShowProduct,
    meta: { showNavbar: true },
  },
  {
    path: "/show-client/:id",
    name: "show-client",
    component: ShowClinet,
    meta: { showNavbar: true },
  },
  {
    path: "/add-user",
    name: "add-user",
    component: AddUser,
    meta: { showNavbar: true },
  },
  {
    path: "/edit-user/:id",
    name: "edit-user",
    component: EditUser,
    meta: { showNavbar: true },
  },
  {
    path: "/show-user/:id",
    name: "show-user",
    component: ShowUser,
    meta: { showNavbar: true },
  },
  {
    path: "/add-campaign",
    name: "add-campaign",
    component: AddCampaigns,
    meta: { showNavbar: true },
  },
  {
    path: "/edit-campaign/:id",
    name: "edit-campaign",
    component: EditCampaign,
    meta: { showNavbar: true },
  },
  {
    path: "/show-campaign",
    name: "show-campaign",
    component: ShowCampaign,
    meta: { showNavbar: true },
  },
  {
    path: "/add-slider",
    name: "add-slider",
    component: AddSlider,
    meta: { showNavbar: true },
  },
  {
    path: "/edit-slider",
    name: "edit-slider",
    component: EditSlider,
    meta: { showNavbar: true },
  },
  {
    path: "/slider",
    name: "slider",
    component: Slider,
    meta: { showNavbar: true },
  },
  {
    path: "/categories",
    name: "categories",
    component: CategoriesView,
    meta: { showNavbar: true },
  },
  {
    path: "/add-category",
    name: "add-category",
    component: AddCategories,
    meta: { showNavbar: true },
  },
  {
    path: "/edit-category/:id",
    name: "edit-category",
    component: EditCategory,
    meta: { showNavbar: true },
  },
  {
    path: "/show-category/:id",
    name: "show-category",
    component: ShowCategory,
    meta: { showNavbar: true },
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.name !== "login" && !token) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
