<template>
  <div class="slider">
    <div class="header">
      <h4>اسليدر</h4>
      <button class="add-product" @click="$router.push('/add-slider')">
        اضافة اسليدر
      </button>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">الصوره</th>
            <th scope="col">اسم الصوره</th>
            <th scope="col">Alt</th>
            <th scope="col">actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(slider, index) in sliders" :key="slider.id">
            <td>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                :data-bs-target="'#exampleModal' + slider.id"
              >
                <img :src="slider.image" alt="" />
              </button>
            </td>
            <td>{{ slider.name }}</td>
            <td>{{ slider.alt }}</td>

            <td>
              <div class="icons">
                <i
                  class="fa-solid fa-trash"
                  @click="DeletSlider(slider.product_id, slider.id, index)"
                ></i>
                <i
                  class="fa-solid fa-pen"
                  @click="EditSlider(slider.product_id, slider.id)"
                ></i>
              </div>
            </td>
            <div
              class="modal fade"
              :id="'exampleModal' + slider.id"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1
                      class="modal-title fs-5"
                      :id="'exampleModalLabel' + slider.id"
                    >
                      عرض الصوره
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <img :src="slider.image" alt="" />
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "SliderPage",
  data() {
    return {
      sliders: [],
      product_id: "",
      image_id: "",
    };
  },

  methods: {
    async fetchSliders() {
      const result = await axios.post("/get-product-images", {
        product_id: localStorage.getItem("id"),
      });
      if (result.data.status === true) {
        this.sliders = result.data.data;
      } else {
        console.log("error");
      }
    },

    async DeletSlider(product_id, image_id, index) {
      console.log(image_id, product_id);

      const result = await axios.post("/delete-product-image", {
        product_id: product_id,
        image_id: image_id,
      });
      this.sliders.splice(index, 1);

      if (result.data.status === true) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "تم الحذف بنجاح",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    EditSlider(id, image_id) {
      localStorage.setItem("id", id);
      localStorage.setItem("image_id", image_id);
      this.$router.push("/edit-slider");
    },
  },
  mounted() {
    this.fetchSliders();
  },
};
</script>

<style scoped>
button.btn.btn-primary {
  background: transparent;
  border: 0;
  padding: 0;
}
button.btn.btn-primary img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.modal-body img {
  width: 100%;
}
button.btn-close {
  margin-right: 68% !important;
}
.modal-header h1 {
  font-family: "bold";
}
button.btn.btn-secondary {
  background: #06456b;
  border: 0;
}
</style>
