<template>
  <lay-out-vue>
    <router-view />
  </lay-out-vue>
</template>
<script>
import LayOutVue from "./components/golable/LayOut.vue";

export default {
  components: {
    LayOutVue,
  },
};
</script>
<style>
@import url("./assets/scss/mainstyle.scss");
</style>
