<template>
  <div class="campaigns">
    <div class="header">
      <h4>الحملات</h4>
      <button class="add-product" @click="$router.push('/add-campaign')">
        اضافة حملة
      </button>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">الصوره</th>
            <th scope="col">الأسم</th>
            <th scope="col">اسم المنتج</th>
            <th scope="col">url</th>
            <th scope="col">تاريخ الأضافه</th>
            <th scope="col">actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(compaign, index) in compaigns" :key="compaign.id">
            <td>{{ compaign.id }}</td>
            <td><img :src="compaign.image" alt="" /></td>
            <td>{{ compaign.name }}</td>
            <td>{{ compaign.name }}</td>
            <td>
              <a :href="compaign.url">{{ compaign.url }}</a>
            </td>
            <td>{{ formatDateToDMY(compaign.created_at) }}</td>
            <td>
              <div class="icons">
                <i
                  class="fa-solid fa-trash"
                  @click="DeletCampaign(compaign.id, index)"
                ></i>
                <i
                  class="fa-solid fa-pen"
                  @click="EditCampaign(compaign.id)"
                ></i>
                <i
                  class="fa-solid fa-eye"
                  @click="ShowCampaign(compaign.id)"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "campaigns_view",
  data() {
    return {
      compaigns: [],
    };
  },
  methods: {
    async getCompaigns() {
      try {
        const response = await axios.get("/get-campaigns");
        this.compaigns = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async DeletCampaign(id, index) {
      try {
        const result = await Swal.fire({
          title: "تأكيد الحذف ؟",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "حذف",
        });

        if (result.isConfirmed) {
          const response = await axios.post("/delete-campaign", { id });

          if (response.data.status) {
            this.compaigns.splice(index, 1);
            await Swal.fire(
              "Deleted!",
              "Your campaign has been deleted.",
              "success"
            );
          } else {
            await Swal.fire(
              "Error!",
              "Something went wrong. Please try again.",
              "error"
            );
          }
        }
      } catch (error) {
        console.error(error);
        await Swal.fire(
          "Error!",
          "Something went wrong. Please try again.",
          "error"
        );
      }
    },
    EditCampaign(id) {
      this.$router.push(`/edit-campaign/${id}`);
      localStorage.setItem("id", id);
    },
    ShowCampaign(id) {
      this.$router.push(`/show-campaign/${id}`);
      localStorage.setItem("id", id);
    },
    formatDateToDMY(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
  mounted() {
    this.getCompaigns();
  },
};
</script>

<style scoped>
/* Add your CSS styling here */
</style>
