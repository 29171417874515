<template>
  <div class="avatar-slider">
    <h4>إضافة صور للسليدر</h4>
    <div class="img-preview">
      <form @submit.prevent="EditSlider">
        <label for="photo">
          <input type="file" id="photo" @change="previewImage" />
          <!-- Conditionally render the text and icon only if no image is selected -->
          <p v-if="!imageSrc">
            اختيار صورة
            <i class="fa-solid fa-image"></i>
          </p>
          <!-- Display the image preview if an image is selected -->
          <div v-if="imageSrc" class="image-container">
            <img :src="imageSrc" alt="Image preview" class="preview-image" />
          </div>
        </label>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
              <label for="name">اسم الصوره</label>
              <input
                class="form-control"
                type="text"
                id="name"
                v-model="name"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="form-group">
              <label for="alt">alt</label>
              <input class="form-control" type="text" id="alt" v-model="alt" />
            </div>
          </div>
        </div>

        <button class="btn" type="submit">اضافة</button>
        <button class="btn btn-back" @click="$router.go(-1)">رجوع</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      selectedFile: null, // Holds the file object
      imageSrc: null, // Holds the source URL of the selected image
      name: "",
      alt: "",
    };
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    EditSlider() {
      if (!this.selectedFile) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "يرجى اختيار صورة.",
        });
        return;
      }

      const id = localStorage.getItem("id");
      const formData = new FormData();
      formData.append("image", this.selectedFile); // Append the actual file
      formData.append("product_id", id);
      formData.append("name", this.name);
      formData.append("alt", this.alt);

      axios
        .post("/store-product-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Image uploaded successfully.",
          });
          console.log(response.data);
          this.$router.push("/slider");
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Failed to upload image.",
          });
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.avatar-slider {
  text-align: center;
}

.img-preview {
  margin-top: 20px;
}

label {
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

p {
  margin: 0;
  font-size: 16px;
}

.preview-image {
  width: 30%;
  height: 90%;
  object-fit: cover;
}
.btn {
  display: block;
  margin-inline-start: auto;
}
button {
  width: 11%;
  margin-top: 1rem;
  font-family: "regular";
  background-color: #06456b;
  color: white;
  border-radius: 5px;
  border: 0;
  padding: 0.5rem;
  transition: 0.7s;
  display: block;
  margin-inline-start: auto;
}
button:hover {
  border: 1px solid #06456b;
  color: #06456b;
  background-color: white;
}
.form-group label {
  border: 0;
  font-family: "regular";
  height: unset;
}
.form-group input {
  width: 100%;
}
</style>
