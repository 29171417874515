<template>
  <div class="login">
    <div class="form-login">
      <h4>تسجيل الدخول</h4>
      <form action="" @submit.prevent>
        <div class="form-group">
          <label for="email">ادخل البريد الألكتروني</label>
          <div class="email">
            <i class="fa-solid fa-envelope"></i>
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              class="form-control"
              v-model="email"
            />
          </div>
          <label for="password">ادخل كلمة المرور</label>
          <div class="password">
            <i class="fa-solid fa-lock" @click="togglePasswordVisibility"></i>
            <input
              id="password"
              :type="passwordType"
              placeholder="Enter your password"
              class="form-control"
              v-model="password"
            />
          </div>
        </div>
        <div class="links">
          <router-link to="/rest-password">هل نسيت كلمة المرور؟</router-link>
        </div>
        <button @click="LoginNow()" type="submit" class="btn">
          تسجيل الدخول
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      passwordType: "password",
      email: "",
      password: "",
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    ...mapActions(["saveToken"]),
    LoginNow() {
      axios
        .post("/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Login successful",
              text: "You have successfully logged in!",
            }).then(() => {
              const token = response.data.data.token;
              this.$store.dispatch("saveToken", token);
              console.log(token);
              this.$router.push({ name: "products" });
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Unexpected Error",
              text: `Unexpected status code: ${response.status}`,
            });
          }
        })
        .catch((error) => {
          let errorMessage = "The provided credentials are incorrect.";
          if (error.response) {
            switch (error.response.status) {
              case 400:
                errorMessage = error.response.data.message;
                break;
              case 401:
                errorMessage = error.response.data.message;
                break;
              default:
                errorMessage = `Error ${error.response.status}: ${
                  error.response.data.message || "An unexpected error occurred."
                }`;
            }
          } else if (error.request) {
            errorMessage =
              "No response received from the server. Please check your network connection.";
          } else {
            errorMessage = `Error: ${error.message}`;
          }

          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: errorMessage,
          });
        });
    },
  },
};
</script>
