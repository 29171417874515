<template>
  <div class="showClinet">
    <h4>عرض العميل</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">اسم العميل</th>
            <th scope="col">رقم الهاتف</th>
            <th scope="col">البريد الألكتروني</th>
            <th scope="col">تاريخ الشراء</th>
            <th scope="col">العنوان</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ client.id }}</td>
            <td>{{ client.name }}</td>
            <td>{{ client.phone }}</td>
            <td>{{ client.email }}</td>
            <td>{{ formatDateToDMY(client.created_at) }}</td>
            <td>{{ client.address }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="back" @click="$router.go(-1)">رجوع</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "client_view",
  data() {
    return {
      client: "",
    };
  },
  mounted() {
    this.getClints();
  },
  methods: {
    async getClints() {
      const id = localStorage.getItem("id");
      try {
        const response = await axios.post("/get-user", { id });
        this.client = response.data.data;
      } catch (error) {
        console.error("There was an error fetching the clients!", error);
      }
    },
    formatDateToDMY(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
